import React, { useState, useEffect, useContext } from "react"
import { Context } from "~/context/Context"
import * as styles from "./Preloader.module.scss"

function Preloader() {
  const [isLogoVisible, setLogoVisibility] = useState(false)
  const { set } = useContext(Context)

  const logoStyles = `${styles.logo} ${
    isLogoVisible ? styles.logo_visible : ""
  }`

  useEffect(() => {
    setTimeout(() => setLogoVisibility(true), 100)
    setTimeout(() => {
      set({ hasPreloaderBeenShown: true })
    }, 1000)
  }, [])

  return (
    <div className={styles.preloader}>
      <div className={styles.inner}>
        <div className={styles.logoWrapper}>
          <svg
            className={logoStyles}
            viewBox="0 0 1653 246"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M97.0477 245.932C68.4351 245.932 45.5014 239.598 28.2465 226.929C11.21 214.043 1.92735 196.023 0.398438 172.871H27.5913C29.1202 189.253 35.7819 201.811 47.5764 210.548C59.5893 219.066 75.9705 223.326 96.7201 223.326C114.193 223.326 128.172 219.394 138.656 211.531C149.358 203.45 154.71 192.638 154.71 179.096C154.71 168.394 151.324 160.094 144.553 154.197C137.782 148.081 126.971 143.604 112.118 140.764L67.5615 131.918C47.2487 128.205 32.178 121.325 22.3493 111.278C12.739 101.231 7.9338 87.9073 7.9338 71.3077C7.9338 51.8686 15.5784 35.815 30.8675 23.1469C46.1567 10.4787 65.7049 4.14467 89.5123 4.14467C115.285 4.14467 135.817 9.71429 151.106 20.8535C166.395 31.7743 175.132 47.5003 177.316 68.0315H150.123C148.157 55.1449 141.823 45.0977 131.121 37.89C120.418 30.4638 106.658 26.7508 89.8399 26.7508C73.4587 26.7508 60.2445 30.7915 50.1974 38.8729C40.3686 46.9543 35.4543 57.4383 35.4543 70.3248C35.4543 81.0272 38.4029 89.1086 44.3001 94.569C50.4158 100.029 61.3366 104.289 77.0626 107.346L122.93 116.848C143.243 120.997 158.095 127.987 167.487 137.815C177.097 147.644 181.902 160.968 181.902 177.786C181.902 197.662 173.93 214.043 157.986 226.929C142.26 239.598 121.947 245.932 97.0477 245.932Z" />
            <path d="M276.449 221.687C280.381 221.687 284.203 221.36 287.916 220.704C291.848 220.049 295.998 219.066 300.366 217.756V240.034C295.124 241.563 290.1 242.655 285.295 243.311C280.709 244.184 276.122 244.621 271.535 244.621C256.246 244.621 244.779 240.908 237.134 233.482C229.708 226.056 225.995 214.807 225.995 199.737V97.8453H201.751V77.2049H225.995V35.269H252.86V77.2049H296.107V97.8453H252.533V198.426C252.533 206.507 254.389 212.405 258.102 216.118C262.034 219.831 268.15 221.687 276.449 221.687Z" />
            <path d="M469.679 77.2049V242H443.141V212.186C439.21 222.67 432.548 230.97 423.156 237.086C413.764 242.983 402.952 245.932 390.721 245.932C372.156 245.932 357.74 240.58 347.475 229.878C337.428 218.957 332.404 203.45 332.404 183.355V77.2049H359.924V178.113C359.924 193.621 363.091 205.306 369.426 213.169C375.978 220.814 385.698 224.636 398.584 224.636C412.344 224.636 423.047 219.722 430.691 209.893C438.336 200.064 442.158 186.085 442.158 167.957V77.2049H469.679Z" />
            <path d="M571.406 245.932C550.001 245.932 532.419 237.959 518.659 222.015C504.898 206.071 498.018 185.321 498.018 159.766C498.018 134.212 504.789 113.462 518.331 97.5177C532.091 81.3549 549.783 73.2734 571.406 73.2734C583.856 73.2734 594.886 76.3313 604.496 82.4469C614.325 88.3442 621.533 96.3164 626.12 106.364V0.868408H653.312V242H627.102V213.169C622.297 222.779 614.871 230.642 604.824 236.758C594.777 242.874 583.638 245.932 571.406 245.932ZM576.976 94.569C561.25 94.569 548.691 100.466 539.299 112.261C530.126 124.055 525.539 139.89 525.539 159.766C525.539 179.424 530.126 195.15 539.299 206.944C548.691 218.739 561.25 224.636 576.976 224.636C592.483 224.636 604.715 218.957 613.67 207.6C622.843 196.242 627.43 180.953 627.43 161.732V157.145C627.43 137.925 622.843 122.745 613.67 111.606C604.715 100.248 592.483 94.569 576.976 94.569Z" />
            <path d="M722.807 42.8044H692.01V9.71428H722.807V42.8044ZM721.169 242H693.976V77.2049H721.169V242Z" />
            <path d="M836.01 73.2734C859.38 73.2734 878.273 81.2456 892.689 97.19C907.104 112.916 914.312 133.775 914.312 159.766C914.312 185.758 907.104 206.617 892.689 222.343C878.273 238.069 859.38 245.932 836.01 245.932C812.639 245.932 793.746 238.069 779.331 222.343C764.915 206.617 757.707 185.758 757.707 159.766C757.707 133.775 764.915 112.916 779.331 97.19C793.746 81.2456 812.639 73.2734 836.01 73.2734ZM836.01 95.2243C820.065 95.2243 807.506 101.012 798.333 112.588C789.159 124.164 784.573 139.89 784.573 159.766C784.573 179.642 789.159 195.368 798.333 206.944C807.506 218.302 820.065 223.981 836.01 223.981C851.954 223.981 864.513 218.302 873.687 206.944C882.86 195.368 887.447 179.642 887.447 159.766C887.447 139.89 882.86 124.164 873.687 112.588C864.513 101.012 851.954 95.2243 836.01 95.2243Z" />
            <path d="M1103.51 245.932C1074.9 245.932 1051.97 239.598 1034.71 226.929C1017.68 214.043 1008.39 196.023 1006.86 172.871H1034.06C1035.59 189.253 1042.25 201.811 1054.04 210.548C1066.06 219.066 1082.44 223.326 1103.19 223.326C1120.66 223.326 1134.64 219.394 1145.12 211.531C1155.82 203.45 1161.18 192.638 1161.18 179.096C1161.18 168.394 1157.79 160.094 1151.02 154.197C1144.25 148.081 1133.44 143.604 1118.58 140.764L1074.03 131.918C1053.71 128.205 1038.64 121.325 1028.82 111.278C1019.2 101.231 1014.4 87.9073 1014.4 71.3077C1014.4 51.8686 1022.04 35.815 1037.33 23.1469C1052.62 10.4787 1072.17 4.14467 1095.98 4.14467C1121.75 4.14467 1142.28 9.71429 1157.57 20.8535C1172.86 31.7743 1181.6 47.5003 1183.78 68.0315H1156.59C1154.62 55.1449 1148.29 45.0977 1137.59 37.89C1126.88 30.4638 1113.12 26.7508 1096.31 26.7508C1079.92 26.7508 1066.71 30.7915 1056.66 38.8729C1046.83 46.9543 1041.92 57.4383 1041.92 70.3248C1041.92 81.0272 1044.87 89.1086 1050.77 94.569C1056.88 100.029 1067.8 104.289 1083.53 107.346L1129.4 116.848C1149.71 120.997 1164.56 127.987 1173.95 137.815C1183.56 147.644 1188.37 160.968 1188.37 177.786C1188.37 197.662 1180.4 214.043 1164.45 226.929C1148.73 239.598 1128.41 245.932 1103.51 245.932Z" />
            <path d="M1227.37 8.07615H1310.26C1344.33 8.07615 1371.85 18.8878 1392.82 40.511C1413.78 61.9158 1424.27 90.0915 1424.27 125.038C1424.27 159.985 1413.78 188.27 1392.82 209.893C1371.85 231.298 1344.33 242 1310.26 242H1227.37V8.07615ZM1395.11 125.038C1395.11 95.9887 1387.57 73.1642 1372.5 56.5646C1357.43 39.9649 1336.68 31.6651 1310.26 31.6651H1255.21V218.411H1310.26C1336.9 218.411 1357.65 210.111 1372.5 193.512C1387.57 176.912 1395.11 154.087 1395.11 125.038Z" />
            <path d="M1652.23 242H1618.15L1524.45 130.608L1488.74 166.974V242H1460.57V8.07615H1488.74V135.194L1611.93 8.07615H1646L1544.11 110.95L1652.23 242Z" />
          </svg>
        </div>
      </div>
    </div>
  )
}

export default Preloader
