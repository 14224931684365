import React, { useState, useRef, useEffect, useContext } from "react"
import { Context } from "~/context/Context"
import videojs from "video.js"
import { useInView } from "react-intersection-observer"
import ProjectShowcaseItem from "./ProjectShowcaseItem"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import Link from "~/components/Link"
import TheaterModal from "~/components/TheaterModal"
import * as styles from "./ProjectShowcase.module.scss"

function ProjectShowcase({ projects }) {
  const [isTheaterOpen, toggleTheater] = useState(false)
  const [isTheaterRendered, setTheaterRendered] = useState(false)
  const [activeProjectIndex, setActiveProjectIndex] = useState(0)
  const [scrollProgress, setScrollProgress] = useState(0)
  const grid = useRef(null)
  const leftColumn = useRef(null)
  const rightColumn = useRef(null)
  const { ref, inView, entry } = useInView()
  const { set } = useContext(Context)

  // tmp?
  projects.forEach((project, index) => {
    project.index = index
  })

  useEffect(() => {
    if (isTheaterOpen) {
      setTheaterRendered(true)
    } else {
      setTimeout(() => setTheaterRendered(false), 400)
    }
    set({ isTheaterModalOpen: isTheaterOpen })
  }, [isTheaterOpen])

  useScrollPosition(() => {
    if (!inView || !leftColumn.current) {
      return
    }
    const rect = leftColumn.current.getBoundingClientRect()
    const scrollPosition = window.innerHeight - rect.top
    const scrollDistance = window.innerHeight + rect.height
    const progress = Math.min(Math.max(scrollPosition / scrollDistance, 0), 1)
    setScrollProgress(progress)
  })

  function gridColumn(index, columnRef) {
    return (
      <div ref={columnRef} className={styles.gridColumn}>
        {projects
          .filter((project, projectIndex) => projectIndex % 2 === index)
          .map((project, projectIndex) => (
            <ProjectShowcaseItem
              project={project}
              key={`${index}-${projectIndex}`}
              onClick={() => {
                setActiveProjectIndex(project.index)
                toggleTheater(true)
                // tmp workaround for autoplay problems on Safari
                setTimeout(() => {
                  const video = entry.target.querySelector(
                    `.theater-slide:nth-child(${project.index + 1}) video`
                  )
                  if (video) {
                    const player = videojs(video)
                    player.currentTime(0)
                    player.play()
                  }
                }, 100)
              }}
            />
          ))}
      </div>
    )
  }

  return (
    <section
      ref={ref}
      className={styles.projectShowcase}
      style={{ "--scroll-progress": scrollProgress }}
    >
      <div ref={grid} className={styles.grid}>
        {gridColumn(0, leftColumn)}
        {gridColumn(1, rightColumn)}
      </div>
      {/* <div className={styles.buttonContainer}>
        <Link className={styles.button} to="/work">
          See all work
        </Link>
      </div> */}
      {isTheaterRendered && (
        <TheaterModal
          projects={projects}
          initiallyActiveProjectIndex={activeProjectIndex}
          isOpen={isTheaterOpen}
          onClose={() => {
            toggleTheater(false)
          }}
        />
      )}
    </section>
  )
}

export default ProjectShowcase
