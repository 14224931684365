// extracted by mini-css-extract-plugin
export var card = "CardSlider-module--card--btVXx";
export var cardImage = "CardSlider-module--cardImage--VPN5i";
export var cardInner = "CardSlider-module--cardInner--JvUwZ";
export var cardSlider = "CardSlider-module--cardSlider--Lnm5K";
export var cardSlider_dragging = "CardSlider-module--cardSlider_dragging--3TtMC";
export var cardSlider_inView = "CardSlider-module--cardSlider_inView--3t5Zz";
export var cardTagline = "CardSlider-module--cardTagline--P3Dqp";
export var cardTitle = "CardSlider-module--cardTitle--j9cLS";
export var cursor = "CardSlider-module--cursor--LeJRy";
export var hiddenGteDesktop = "CardSlider-module--hidden-gte-desktop--6NINF";
export var hiddenLtDesktop = "CardSlider-module--hidden-lt-desktop--qVAg5";
export var slider = "CardSlider-module--slider--fii-k";
export var sliderWrapper = "CardSlider-module--sliderWrapper--5UKdf";
export var title = "CardSlider-module--title--CV7YI";