// extracted by mini-css-extract-plugin
export var button = "ProjectShowcase-module--button--HeTA9";
export var buttonContainer = "ProjectShowcase-module--buttonContainer--YuN8W";
export var grid = "ProjectShowcase-module--grid--sTou-";
export var gridColumn = "ProjectShowcase-module--gridColumn--4-zeT";
export var hiddenGteDesktop = "ProjectShowcase-module--hidden-gte-desktop--ALCLu";
export var hiddenLtDesktop = "ProjectShowcase-module--hidden-lt-desktop--8mXdb";
export var project = "ProjectShowcase-module--project--8lGbO";
export var projectClient = "ProjectShowcase-module--projectClient--CMkis";
export var projectInfo = "ProjectShowcase-module--projectInfo--LgpMk";
export var projectShowcase = "ProjectShowcase-module--projectShowcase--tC64Z";
export var projectThumbnail = "ProjectShowcase-module--projectThumbnail--EXMnb";
export var projectTitle = "ProjectShowcase-module--projectTitle--BN9aH";
export var project_inView = "ProjectShowcase-module--project_inView--nDjkG";
export var project_large = "ProjectShowcase-module--project_large--EC2md";
export var project_medium = "ProjectShowcase-module--project_medium--R6-5c";
export var project_small = "ProjectShowcase-module--project_small--NPgoY";