import React, { useState, useRef, useEffect, useContext } from "react"
import { Context } from "~/context/Context"
import Video from "~/components/Video"
import videojs from "video.js"
import { Swiper, SwiperSlide } from "swiper/react"
import anime from "animejs/lib/anime.es.js"
import { useInView } from "react-intersection-observer"
import useWindowSize from "~/hooks/useWindowSize"
import "swiper/css"
import TheaterModal from "~/components/TheaterModal"
import breakpoints from "../../styles/breakpoints"
import * as styles from "./VideoSlider.module.scss"

function VideoSlider({ projects }) {
  const [slidesPerView, setSlidesPerView] = useState(1.5)
  const [swiper, setSwiper] = useState(null)
  const [client, setClient] = useState(null)
  const [title, setTitle] = useState(null)
  const [isTheaterOpen, toggleTheater] = useState(false)
  const [isTheaterRendered, setTheaterRendered] = useState(false)
  const [activeProjectIndex, setActiveProjectIndex] = useState(0)
  const clientInner = useRef(null)
  const titleInner = useRef(null)
  const indexesInner = useRef(null)
  const progressBar = useRef(null)
  const progressBarLevel = useRef(null)
  const progressBarAnimation = useRef(null)
  const {
    data: { hasPreloaderBeenShown, isAboutPanelVisible },
    set,
  } = useContext(Context)
  const { width: windowWidth, height: windowHeight } = useWindowSize()
  const swiperSpeed = 350
  const swiperChangeSpeed = 450
  const autoplayDelay = 10000
  const preloaderDelay = hasPreloaderBeenShown ? 0 : 1700
  const currentIndex1 = (swiper?.realIndex || 0) + 1
  const { ref, inView, entry } = useInView({
    rootMargin: "-400px 0px 0px 0px",
  })

  useEffect(() => {
    updateAllResponsiveSrc()
  }, [windowWidth])

  useEffect(() => {
    const margin = windowWidth >= breakpoints.tablet ? 100 : 50
    setSlidesPerView(windowWidth / (windowWidth - margin))
  }, [windowWidth])

  useEffect(() => {
    if (swiper) {
      setTimeout(() => animateSlide(), preloaderDelay)
    }
  }, [swiper])

  useEffect(() => {
    if (!progressBarAnimation.current || !swiper) {
      return
    }
    const currentSlide = swiper.slides[swiper.activeIndex]
    const currentVideo = currentSlide.querySelector("video")
    const currentPlayer = videojs(currentVideo)
    if (isTheaterOpen) {
      setTheaterRendered(true)
      progressBarAnimation.current.pause()
      currentPlayer.pause()
    } else {
      setTimeout(() => setTheaterRendered(false), 400)
      progressBarAnimation.current.paused &&
        progressBarAnimation.current.restart()
      currentPlayer.play()
    }
    set({ isTheaterModalOpen: isTheaterOpen })
  }, [isTheaterOpen])

  useEffect(() => {
    if (!progressBarAnimation.current || !swiper || isTheaterOpen) {
      return
    }
    const currentSlide = swiper.slides[swiper.activeIndex]
    const currentVideo = currentSlide.querySelector("video")
    const currentPlayer = videojs(currentVideo)
    if (inView && !isAboutPanelVisible) {
      progressBarAnimation.current.play()
      currentPlayer.play()
    } else {
      progressBarAnimation.current.pause()
      currentPlayer.pause()
    }
    // TODO: refactor
    window.videoSliderInView = inView
  }, [inView, isAboutPanelVisible])

  useEffect(() => {
    // TODO: refactor
    window.activeVideoSliderIndex = activeProjectIndex
  }, [activeProjectIndex])

  function onSlideClick(index) {
    if (index === swiper.realIndex) {
      openTheater(index)
    } else {
      swiper.slideNext(swiperChangeSpeed)
    }
  }

  function onSlideChange(swiper) {
    setActiveProjectIndex(swiper.realIndex)
    animateSlide()
  }

  function animateSlide() {
    if (!swiper) {
      return
    }

    const currentProject = projects[swiper.realIndex]
    const currentSlide = swiper.slides[swiper.activeIndex]
    const previousSlide = swiper.slides[swiper.previousIndex]
    const currentVideo = currentSlide.querySelector("video")
    const previousVideo = previousSlide.querySelector("video")
    const currentPlayer = videojs(currentVideo)
    const previousPlayer = videojs(previousVideo)

    setClient(currentProject.client)
    setTitle(currentProject.title)
    previousPlayer.pause()
    currentPlayer.play()

    const animationSettings = {
      duration: 1000,
      easing: "cubicBezier(0.57, 0.06, 0.05, 0.95)",
    }

    anime.remove([
      clientInner.current,
      indexesInner.current,
      titleInner.current,
      progressBar.current,
      progressBarLevel.current,
    ])
    anime({
      ...animationSettings,
      targets: [clientInner.current, indexesInner.current],
      translateY: ["200%", "0%"],
    })
    anime({
      ...animationSettings,
      targets: titleInner.current,
      translateY: ["300%", "0%"],
      delay: 150,
    })
    anime({
      ...animationSettings,
      targets: progressBar.current,
      opacity: [0, 1],
      delay: 150,
    })
    progressBarAnimation.current = anime({
      targets: progressBarLevel.current,
      translateX: ["-100%", "0%"],
      duration: autoplayDelay,
      easing: "linear",
      complete: () => {
        swiper.slideNext(swiperChangeSpeed)
      },
    })
  }

  function openTheater(projectIndex) {
    toggleTheater(true)

    // tmp workaround for autoplay problems on Safari
    setTimeout(() => {
      const video = entry.target.querySelector(
        `.theater-slide:nth-child(${projectIndex + 1}) video`
      )
      if (video) {
        const player = videojs(video)
        player.currentTime(0)
        player.play()
      }
    }, 100)
  }

  function onTheaterChangeSlide(index) {
    if (!swiper) {
      return
    }
    swiper.slideToLoop(index, 0, false)
    const currentSlide = swiper.slides[swiper.activeIndex]
    const currentVideo = currentSlide.querySelector("video")
    const currentPlayer = videojs(currentVideo)
    progressBarAnimation.current.pause()
    currentPlayer.pause()
  }

  function setResponsiveSrc(
    player,
    videoFiles,
    poster,
    likelyPlaying,
    currentTime,
    index
  ) {
    let size = "360p"
    const isHighDpi = window.devicePixelRatio > 1
    const elementWidth =
      Math.max(
        windowWidth - (windowWidth >= breakpoints.tablet ? 100 : 50),
        windowHeight
      ) * (isHighDpi ? 1.5 : 1)
    if (elementWidth > 1440) {
      size = "1080p"
    } else if (elementWidth > 1100) {
      size = "720p"
    } else if (elementWidth > 860) {
      size = "540p"
    }
    const newFile = videoFiles.find((file) => file.rendition === size)
    let hasDelay = typeof index !== "undefined" && index > 0
    if (index === 0) {
      const slide = player.el_.closest(".swiper-slide")
      if (slide.classList.contains("swiper-slide-duplicate")) {
        hasDelay = true
      }
    }

    function set() {
      if (player.currentSrc() !== newFile.link) {
        player.src({
          type: newFile.type,
          src: newFile.link,
        })
        player.poster(poster)
        if (likelyPlaying) {
          player.currentTime(currentTime)
          player.play()
        }
      }
    }

    if (hasDelay) {
      setTimeout(() => set(), 1000)
    } else {
      set()
    }
  }

  function updateAllResponsiveSrc() {
    if (!swiper) {
      return
    }
    swiper.slides.forEach((slide) => {
      const index = slide.dataset.swiperSlideIndex
      const videoFiles = projects[index].shortVideo.files
      const poster = projects[index].shortVideo.poster
      const video = slide.querySelector("video")
      if (video) {
        const player = videojs(video)
        setResponsiveSrc(
          player,
          videoFiles,
          poster,
          !player.paused(),
          player.currentTime()
        )
      }
    })
  }

  return (
    <section ref={ref} className={styles.videoSlider}>
      <Swiper
        className={styles.slider}
        spaceBetween={0}
        slidesPerView={slidesPerView}
        speed={swiperSpeed}
        loop={true}
        threshold={10}
        onSlideChange={onSlideChange}
        onSwiper={setSwiper}
      >
        {projects.map(({ shortVideo: { poster, files } }, index) => (
          <SwiperSlide
            className={styles.slide}
            data-index={index}
            key={index}
            onClick={() => onSlideClick(index)}
          >
            <Video
              options={{
                muted: true,
                loop: true,
                autoplay: false,
                controls: false,
                playsinline: true,
                preload: "auto",
                // poster: poster,
                loadingSpinner: false,
                userActions: {
                  click: false,
                  doubleClick: false,
                  hotkeys: false,
                },
              }}
              onReady={(player) =>
                setResponsiveSrc(player, files, poster, null, null, index)
              }
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={styles.info}>
        <h2 className={styles.client}>
          <span ref={clientInner} className={styles.clientInner}>
            {client}
          </span>
        </h2>
        <h3 className={styles.title}>
          <span ref={titleInner} className={styles.titleInner}>
            {title}
          </span>
        </h3>
        <div className={styles.indexes}>
          <div ref={indexesInner} className={styles.indexesInner}>
            <div className={styles.index}>
              <span>{currentIndex1}</span>
              <span>/</span>
              <span>{projects.length}</span>
            </div>
          </div>
        </div>
      </div>
      <div ref={progressBar} className={styles.progressBar}>
        <div ref={progressBarLevel} className={styles.progressBarLevel}></div>
      </div>
      {isTheaterRendered && (
        <TheaterModal
          projects={projects}
          initiallyActiveProjectIndex={activeProjectIndex}
          isOpen={isTheaterOpen}
          onChangeSlide={(index) => onTheaterChangeSlide(index)}
          onClose={() => toggleTheater(false)}
        />
      )}
    </section>
  )
}

export default VideoSlider
