import React from "react"
import { useInView } from "react-intersection-observer"
import Link from "~/components/Link"
import Clock from "./Clock"
import NewsletterForm from "./NewsletterForm"
import * as styles from "./Footer.module.scss"

function Footer({ headline, team, offices, navigationBar }) {
  const [title, titleInView] = useInView()
  const [columns1, columns1InView] = useInView()
  const [columns2, columns2InView] = useInView()
  const [bar, barInView] = useInView()

  const titleStyles = `${styles.title} ${
    titleInView ? styles.title_inView : ""
  }`

  const columnsStyles = (elementInView) =>
    `${styles.columns} ${elementInView ? styles.columns_inView : ""}`

  const barStyles = `${styles.bar} ${barInView ? styles.bar_inView : ""}`

  return (
    <footer className={styles.footer}>
      <h2 ref={title} className={titleStyles}>
        {headline}
      </h2>
      <div ref={columns1} className={columnsStyles(columns1InView)}>
        {team.map(({ name, title, email }, index) => (
          <div className={styles.columnCell} key={index}>
            <a className={styles.columnLink} href={`mailto:${email}`}>
              <h3>{name}</h3>
              <h4 className={styles.role}>{title}</h4>
            </a>
          </div>
        ))}
        <div className={styles.columnCell}>
          <ul>
            {navigationBar.links.map(({ text, url, email }, index) => (
              <li
                key={index}
                style={{
                  display: text == "Esfuerzo Mezcal" ? "none" : "",
                }}
              >
                <Link
                  className={styles.columnLink}
                  to={url || (email && `mailto:${email}`)}
                >
                  {text}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div ref={columns2} className={columnsStyles(columns2InView)}>
        {offices.map(({ city, timeZone, email }, index) => (
          <div className={styles.columnCell} key={index}>
            <span className={styles.columnLink}>
              <h3>{city}</h3>
              <h4 className={styles.role}>
                <Clock timeZone={timeZone} />
              </h4>
            </span>
          </div>
        ))}
      </div>
      <div ref={bar} className={barStyles}>
        {/*<NewsletterForm />*/}
        <div>&nbsp;</div>
        {/* remove when newsletter comes back */}
        <div className={styles.navigation}>
          <p>{navigationBar.copyrightText}</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
