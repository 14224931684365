import React, { useState, useEffect } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import { FreeMode } from "swiper"
import "swiper/css"
import { useInView } from "react-intersection-observer"
import useWindowSize from "~/hooks/useWindowSize"
import DragCursor from "./DragCursor"
import breakpoints from "../../styles/breakpoints"
import * as styles from "./CardSlider.module.scss"

function CardSlider({ title, cards, onCardClick }) {
  const [slidesPerView, setSlidesPerView] = useState(3.346)
  const [spaceBetween, setSpaceBetween] = useState(40)
  const [isCursorVisible, setCursorVisibility] = useState(false)
  const [cursorX, setCursorX] = useState(null)
  const [cursorY, setCursorY] = useState(null)
  const [isSliderDragging, setSliderDragging] = useState(null)
  const { ref, inView } = useInView({
    rootMargin: "0px 0px -122px 0px",
  })
  const { width: windowWidth } = useWindowSize()

  const cardSliderStyles = `${styles.cardSlider} ${
    isSliderDragging ? styles.cardSlider_dragging : ""
  } ${inView ? styles.cardSlider_inView : ""}`

  useEffect(() => {
    const isGteTablet = windowWidth >= breakpoints.tablet
    setSlidesPerView(isGteTablet ? 3.346 : 1.25426)
    setSpaceBetween(isGteTablet ? 40 : 25)
  }, [windowWidth])

  function toggleCursor(toVisible) {
    setCursorVisibility(toVisible)
  }

  function onPointerUp() {
    setSliderDragging(false)
  }

  function onMouseMove(event) {
    setCursorX(event.clientX)
    setCursorY(event.clientY)
  }

  function onSliderMove(swiper) {
    setCursorX(swiper.touches.currentX)
    setCursorY(swiper.touches.currentY - window.scrollY)
    setSliderDragging(true)
  }

  return (
    <section ref={ref} className={cardSliderStyles} onMouseMove={onMouseMove}>
      <h2 className={styles.title}>{title}</h2>
      <div
        className={styles.sliderWrapper}
        role="none"
        onMouseEnter={() => toggleCursor(true)}
        onMouseLeave={() => toggleCursor(false)}
        onPointerUp={onPointerUp}
        onClick={onCardClick}
      >
        <Swiper
          className={styles.slider}
          spaceBetween={spaceBetween}
          slidesPerView={slidesPerView}
          loop={true}
          speed={300}
          updateOnImagesReady={false}
          modules={[FreeMode]}
          freeMode={{
            enabled: true,
            minimumVelocity: 0,
          }}
          onSliderMove={onSliderMove}
        >
          {[...cards, ...cards, ...cards].map(
            ({ title, tagline, image }, index) => (
              <SwiperSlide className={styles.card} key={index}>
                <div className={styles.cardInner}>
                  <GatsbyImage
                    className={styles.cardImage}
                    image={getImage(image)}
                    alt=""
                  />
                </div>
                <h3 className={styles.cardTitle}>{title}</h3>
                <h4 className={styles.cardTagline}>{tagline}</h4>
              </SwiperSlide>
            )
          )}
        </Swiper>
      </div>
      <div
        className={styles.cursor}
        style={{
          visibility: isCursorVisible ? "visible" : "hidden",
          "--top": `${cursorY}px`,
          "--left": `${cursorX}px`,
        }}
      >
        <DragCursor />
      </div>
    </section>
  )
}

export default CardSlider
