import React, { useState, useRef, useEffect, useContext } from "react"
import { Context } from "~/context/Context"
import Video from "~/components/Video"
import Link from "~/components/Link"
import videojs from "video.js"
import { Swiper, SwiperSlide } from "swiper/react"
import anime from "animejs/lib/anime.es.js"
import useWindowSize from "~/hooks/useWindowSize"
import "swiper/css"
import * as styles from "./TheaterModal.module.scss"
import breakpoints from "../../styles/breakpoints"

function TheaterModal({
  projects,
  initiallyActiveProjectIndex,
  isOpen,
  onChangeSlide,
  onClose,
}) {
  const { set } = useContext(Context)
  const [activeProjectIndex, setActiveProjectIndex] = useState(
    initiallyActiveProjectIndex
  )
  const [isInnerVisible, setInnerVisibility] = useState(false)
  const [isCursorVisible, setCursorVisibility] = useState(true)
  const [cursorX, setCursorX] = useState(null)
  const [cursorY, setCursorY] = useState(null)
  const [isVideoPlaying, setVideoPlaying] = useState(true)
  const [hasInteracted, setHasInteracted] = useState(false)
  const [swiper, setSwiper] = useState(null)
  const overlay = useRef(null)
  const { width: windowWidth } = useWindowSize()
  const swiperSpeed = 350
  const swiperChangeSpeed = 450

  const theaterModalStyles = `${styles.theaterModal} ${
    isInnerVisible ? styles.theaterModal_innerVisible : ""
  }`

  useEffect(() => {
    if (!swiper) {
      return
    }
    const isPointer = matchMedia("(hover: hover), (pointer: fine)").matches
    const isDesktop = windowWidth >= breakpoints.desktop
    swiper.allowTouchMove = isPointer && isDesktop
  }, [swiper, windowWidth])

  useEffect(() => {
    if (!swiper) {
      return
    }
    swiper.slideTo(initiallyActiveProjectIndex, 0)
  }, [initiallyActiveProjectIndex, swiper])

  useEffect(() => {
    if (typeof onChangeSlide === "undefined") {
      return
    }
    onChangeSlide(activeProjectIndex)
  }, [activeProjectIndex])

  useEffect(() => {
    setTimeout(() => {
      const video = getVideo()
      if (video) {
        const player = videojs(video)
        if (isOpen) {
          player.currentTime(0)
          player.play()
        }
      }
    }, 510)

    if (!isOpen) {
      setCursorX(null)
      setCursorY(null)
    }
    toggleOverlay(isOpen)
    setVideoPlaying(isOpen)
  }, [isOpen])

  useEffect(() => {
    const video = getVideo()
    if (!video) {
      return
    }
    const player = videojs(video)
    if (isVideoPlaying) {
      if (hasInteracted) {
        player.play()
      }
    } else {
      player.pause()
    }
  }, [isVideoPlaying])

  function toggleOverlay(isVisible) {
    if (isVisible) {
      anime({
        targets: overlay.current,
        opacity: [0, 1],
        duration: 250,
        easing: "easeOutQuad",
        complete: () => {
          setInnerVisibility(true)
          anime({
            targets: overlay.current,
            translateX: ["0%", "100%"],
            duration: 400,
            delay: 50,
            easing: "cubicBezier(0.57, 0.06, 0.05, 0.95)",
          })
        },
      })
    } else {
      anime({
        targets: overlay.current,
        translateX: ["100%", "0%"],
        duration: 150,
        easing: "easeInQuad",
        complete: () => {
          setInnerVisibility(false)
          anime({
            targets: overlay.current,
            opacity: [1, 0],
            duration: 250,
            easing: "easeOutQuad",
          })
        },
      })
    }
  }

  function getVideo(indexName = "activeIndex") {
    if (!swiper || !swiper.slides) {
      return
    }
    const index = swiper[indexName]
    const currentSlide = swiper.slides[index]
    const currentVideo = currentSlide?.querySelector("video")
    return currentVideo
  }

  function onSlideChange(swiper) {
    if (!isOpen) {
      return
    }
    setActiveProjectIndex(swiper.realIndex)
    setVideoPlaying(true)
    const previousVideo = getVideo("previousIndex")
    const currentVideo = getVideo()
    if (previousVideo) {
      const previousPlayer = videojs(previousVideo)
      previousPlayer.pause()
      setTimeout(() => {
        previousPlayer.currentTime(0)
      }, swiperChangeSpeed)
    }
    if (currentVideo) {
      const currentPlayer = videojs(currentVideo)
      currentPlayer.play()
    }
  }

  function onMouseMove(event) {
    setCursorX(event.clientX)
    setCursorY(event.clientY)
  }

  function onClick() {
    setHasInteracted(true)
    setVideoPlaying(!isVideoPlaying)
  }

  function onPlayerReady(player, videoFiles) {
    setResponsiveSrc(player, videoFiles)
    player.on("timeupdate", () => {
      const progress = player.currentTime() / player.duration()
      const slide = player.tech_.el_.closest(".swiper-slide")
      slide.style.setProperty("--progress", progress)
    })
  }

  function setResponsiveSrc(player, videoFiles) {
    let size = "360p"
    const isHighDpi = window.devicePixelRatio > 1
    const elementWidth = windowWidth * (isHighDpi ? 1.5 : 1)
    if (elementWidth > 1440) {
      size = "1080p"
    } else if (elementWidth > 1100) {
      size = "720p"
    } else if (elementWidth > 860) {
      size = "540p"
    }
    const newFile = videoFiles.find((file) => file.rendition === size)

    if (player.currentSrc() !== newFile?.link) {
      player.src({
        type: newFile.type,
        src: newFile.link,
      })
    }
  }

  function centerNavButton(e, direction) {
    const button = e.target.closest("button")
    const buttonImage = button.querySelector("img")
    const swiperEl = button.closest(".swiper")

    const newImage = document.createElement("img")
    newImage.src = buttonImage.src

    newImage.style.position = "fixed"
    newImage.style.width = "100vw"
    newImage.style.height = "100%"
    newImage.style.objectFit = "cover"
    newImage.style.top = 0
    newImage.style.left = 0
    newImage.style.zIndex = 9
    newImage.style.pointerEvents = "none"
    swiperEl.appendChild(newImage)

    const translateX =
      direction === "prev" ? -window.innerWidth + 265 : window.innerWidth - 265

    anime({
      targets: newImage,
      translateX: [translateX, 0],
      duration: swiperChangeSpeed,
      easing: "cubicBezier(0.57, 0.06, 0.05, 0.95)",
      complete: () => {
        if (direction === "prev") {
          swiper.slidePrev(0)
        } else {
          swiper.slideNext(0)
        }
        anime({
          targets: newImage,
          opacity: [1, 0],
          duration: 350,
          delay: 100,
          easing: "easeOutQuad",
          complete: () => {
            newImage.remove()
          },
        })
      },
    })
  }

  return (
    <div className={theaterModalStyles}>
      <div className={styles.theaterModalInner}>
        <div className={styles.header}>
          <div className={styles.headerColumn}>
            {activeProjectIndex + 1} – {projects.length}
          </div>
          <div className={styles.headerColumn}>
            <h2 className={styles.client}>
              {projects[activeProjectIndex].client}
            </h2>
            <h3 className={styles.title}>
              {projects[activeProjectIndex].title}
            </h3>
          </div>
          <div className={styles.headerColumn}>
            <button
              className={styles.closeButton}
              type="button"
              onClick={() => {
                onClose()
              }}
            >
              Close
            </button>
          </div>
        </div>
        <Swiper
          className={styles.slider}
          spaceBetween={0}
          slidesPerView={1}
          speed={swiperSpeed}
          loop={false}
          threshold={10}
          onSlideChange={onSlideChange}
          onSwiper={setSwiper}
        >
          {projects.map(({ fullVideo: { poster, files } }, index) => (
            <SwiperSlide
              className={`theater-slide ${styles.slide}`}
              key={index}
            >
              <div
                className={styles.videoWrapper}
                onMouseMove={onMouseMove}
                onMouseEnter={() => setCursorVisibility(true)}
                onMouseLeave={() => setCursorVisibility(false)}
                onClick={onClick}
              >
                <Video
                  options={{
                    controls: false,
                    playsinline: true,
                    poster: poster,
                    loadingSpinner: false,
                    userActions: {
                      click: false,
                      doubleClick: false,
                      hotkeys: false,
                    },
                  }}
                  onReady={(player) => onPlayerReady(player, files)}
                />
                <div className={styles.progressBar}>
                  <div className={styles.progressBarInner}></div>
                </div>
              </div>
              <div>
                <button
                  className={styles.navButton}
                  type="button"
                  onClick={(e) => {
                    // swiper.slidePrev(swiperChangeSpeed)
                    centerNavButton(e, "prev")
                  }}
                >
                  <img
                    className={styles.navButtonPoster}
                    src={projects[index - 1]?.fullVideo?.poster}
                    alt=""
                  />
                  <span className={styles.navButtonInner}>
                    <span className={styles.navButtonInfo}>
                      <span className={styles.navButtonLabel}>Prev</span>
                      <span className={styles.navButtonClient}>
                        {projects[index - 1]?.client}
                      </span>
                      <span className={styles.navButtonTitle}>
                        {projects[index - 1]?.title}
                      </span>
                    </span>
                  </span>
                </button>
                {index < projects.length - 1 ? (
                  <button
                    className={styles.navButton}
                    type="button"
                    onClick={(e) => {
                      // swiper.slideNext(swiperChangeSpeed)
                      centerNavButton(e, "next")
                    }}
                  >
                    <img
                      className={styles.navButtonPoster}
                      src={projects[index + 1]?.fullVideo?.poster}
                      alt=""
                    />
                    <span className={styles.navButtonInner}>
                      <span className={styles.navButtonInfo}>
                        <span className={styles.navButtonLabel}>Next</span>
                        <span className={styles.navButtonClient}>
                          {projects[index + 1]?.client}
                        </span>
                        <span className={styles.navButtonTitle}>
                          {projects[index + 1]?.title}
                        </span>
                      </span>
                    </span>
                  </button>
                ) : (
                  <span></span>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className={styles.cursor}
          style={{
            visibility: isCursorVisible ? "visible" : "hidden",
            top: cursorY,
            left: cursorX,
          }}
        >
          {isVideoPlaying ? "Pause" : "Play"}
        </div>
      </div>
      <div ref={overlay} className={styles.overlay}></div>
    </div>
  )
}

export default TheaterModal
