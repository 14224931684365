// TODO: extra_large hi dpi

import React, { useRef, useState, useEffect } from "react"
import Video from "~/components/Video"
import { useInView } from "react-intersection-observer"
import useWindowSize from "~/hooks/useWindowSize"
import * as styles from "./ProjectShowcase.module.scss"

function ProjectShowcaseItem({ project, onClick }) {
  const { client, title, thumbnailSize, shortVideo, fullVideo } = project
  const [isPlayerReady, setPlayerReady] = useState(false)
  const playerRef = useRef(null)

  const { ref, inView } = useInView()
  const { width: windowWidth } = useWindowSize()

  function variantStyles(className, variantName) {
    return `${styles[className]} ${styles[`${className}_${variantName}`]} ${
      inView ? styles.project_inView : ""
    }`
  }

  function onPlayerReady(player) {
    playerRef.current = player
    setPlayerReady(true)
  }

  useEffect(() => {
    setTimeout(() => setResponsiveSrc(), 500)
  }, [windowWidth, isPlayerReady])

  useEffect(() => {
    const player = playerRef.current
    if (!player) {
      return
    }
    if (inView) {
      player.play()
    } else {
      player.pause()
    }
  }, [inView])

  function setResponsiveSrc() {
    const player = playerRef.current
    if (!player) {
      return
    }
    let size = "360p"
    const isHighDpi = window.devicePixelRatio > 1
    if (
      (thumbnailSize === "small" && windowWidth > (isHighDpi ? 1370 : 1990)) ||
      (thumbnailSize === "medium" && windowWidth > (isHighDpi ? 1140 : 1680)) ||
      (thumbnailSize === "large" && windowWidth > (isHighDpi ? 940 : 1368)) ||
      thumbnailSize === "extra_large"
    ) {
      size = "540p"
    }
    const newFile = shortVideo.files.find((file) => file.rendition === size)

    if (player.currentSrc() !== newFile.link) {
      player.src({
        type: newFile.type,
        src: newFile.link,
      })
      if (inView) {
        player.play()
      }
    }
  }

  return (
    <div
      ref={ref}
      className={variantStyles("project", thumbnailSize)}
      onClick={onClick}
    >
      <div className={styles.projectThumbnail}>
        <Video
          options={{
            muted: true,
            loop: true,
            autoplay: false,
            controls: false,
            playsinline: true,
            fluid: true,
            preload: "metadata",
            loadingSpinner: false,
            userActions: {
              click: false,
              doubleClick: false,
              hotkeys: false,
            },
          }}
          onReady={onPlayerReady}
        />
      </div>
      <div className={styles.projectInfo}>
        <h3 className={styles.projectClient}>{client}</h3>
        <h4 className={styles.projectTitle}>{title}</h4>
      </div>
    </div>
  )
}

export default ProjectShowcaseItem
