import React, { useState, useRef, useEffect } from "react"
import anime from "animejs/lib/anime.es.js"
import useFontFaceObserver from "use-font-face-observer"
import { useInView } from "react-intersection-observer"
import useWindowSize from "~/hooks/useWindowSize"
import breakpoints from "../../styles/breakpoints"
import * as styles from "./Scroller.module.scss"

function Scroller({ mainText, buttonText, onButtonClick }) {
  const [isZoomedOut, setZoomedOut] = useState(false)
  const [isLeftFragmentVisible, setLeftFragmentVisibility] = useState(false)
  const lines = useRef(null)
  const oddLinesanimation = useRef(null)
  const evenLinesanimation = useRef(null)
  const isFontLoaded = useFontFaceObserver([{ family: "Untitled Sans wf" }])
  const { ref, inView } = useInView({
    rootMargin: "-100px",
  })
  const { width: windowWidth } = useWindowSize()

  const scrollerStyles = `${styles.scroller} ${
    inView ? styles.scroller_inView : ""
  } ${isZoomedOut ? styles.scroller_zoomedOut : ""}`

  const lineFragmentStyles = (lineIndex, partIndex, fragmentIndex) => {
    const isLeftCentralFragment =
      lineIndex === 1 && partIndex === 1 && fragmentIndex === 4
    const isHidden = isLeftCentralFragment && !isLeftFragmentVisible
    return `${styles.lineFragment} ${
      isHidden ? styles.lineFragment_hidden : ""
    }`
  }

  function play() {
    oddLinesanimation.current?.restart()
    evenLinesanimation.current?.restart()
  }

  function stop() {
    oddLinesanimation.current?.pause()
    evenLinesanimation.current?.pause()
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (inView) {
        play()
      } else {
        stop()
        setLeftFragmentVisibility(false)
      }
    })
    return () => {
      clearTimeout(timeout)
    }
  }, [inView])

  useEffect(() => {
    if (!isFontLoaded) {
      return
    }

    const oddLines = [],
      evenLines = []
    const nodes = [...lines.current.children]
    nodes.forEach((node, index) =>
      (index % 2 === 0 ? oddLines : evenLines).push(node)
    )
    const fragment = nodes[0].children[0].children[0]
    const duration =
      fragment.offsetWidth * (windowWidth >= breakpoints.desktop ? 5 : 10)

    const animationSettings = {
      translateX: [0, -fragment.offsetWidth],
      duration,
      easing: "linear",
      loop: true,
      autoplay: false,
    }

    oddLinesanimation.current = anime({
      ...animationSettings,
      targets: oddLines,
      direction: "reverse",
    })

    evenLinesanimation.current = anime({
      ...animationSettings,
      targets: evenLines,
      loopComplete: () => {
        setLeftFragmentVisibility(true)
      },
    })
  }, [isFontLoaded])

  return (
    <section ref={ref} className={scrollerStyles}>
      <div className={styles.scrollerInner}>
        <div ref={lines} className={styles.lines}>
          {[...Array(3)].map((item, index) => (
            <div className={styles.line} aria-hidden="true" key={index}>
              {[...Array(2)].map((item, partIndex) => (
                <div className={styles.linePart} key={partIndex}>
                  {[...Array(5)].map((item, fragmentIndex) => (
                    <div
                      className={lineFragmentStyles(
                        index,
                        partIndex,
                        fragmentIndex
                      )}
                      key={fragmentIndex}
                    >
                      {mainText}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <button
        className={styles.button}
        type="button"
        onMouseEnter={() => {
          setZoomedOut(true)
        }}
        onMouseLeave={() => {
          setZoomedOut(false)
        }}
        onClick={onButtonClick}
      >
        {buttonText}
      </button>
    </section>
  )
}

export default Scroller
