import React from "react"

function DragCursor() {
  return (
    <svg
      width="41"
      height="16"
      viewBox="0 0 41 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 7.79423L9.75 0V6.79423L31.25 6.79423V0L41 7.79423L31.25 15.5885V8.79423L9.75 8.79423V15.5885L0 7.79423Z" />
    </svg>
  )
}

export default DragCursor
