// extracted by mini-css-extract-plugin
export var client = "VideoSlider-module--client--SYxue";
export var clientInner = "VideoSlider-module--clientInner--1ss0K";
export var hiddenGteDesktop = "VideoSlider-module--hidden-gte-desktop--fBsBi";
export var hiddenLtDesktop = "VideoSlider-module--hidden-lt-desktop--+WKTc";
export var index = "VideoSlider-module--index--jOjeh";
export var indexes = "VideoSlider-module--indexes--Ezi8+";
export var indexesInner = "VideoSlider-module--indexesInner--8NLOv";
export var info = "VideoSlider-module--info--5JG9L";
export var progressBar = "VideoSlider-module--progressBar--Dij1+";
export var progressBarLevel = "VideoSlider-module--progressBarLevel--ZFifk";
export var slide = "VideoSlider-module--slide--KCJ4G";
export var slider = "VideoSlider-module--slider--e98KD";
export var title = "VideoSlider-module--title--MgBbR";
export var titleInner = "VideoSlider-module--titleInner--Pr9-y";
export var videoSlider = "VideoSlider-module--videoSlider--t9H+m";