import React, { useState, useRef, useEffect } from "react"
import { DateTime } from "luxon"
import * as styles from "./Footer.module.scss"

function Clock({ timeZone }) {
  const [hour, setHour] = useState(null)
  const [minute, setMinute] = useState(null)
  const [period, setPeriod] = useState(null)
  const ref = useRef(null)
  const timeout = 2000

  useEffect(() => {
    setTime()
    refresh()
  }, [])

  function setTime() {
    if (!ref.current) {
      return
    }
    const time = DateTime.now().setZone(timeZone)
    const period = time.hour >= 12 ? "pm" : "am"
    setHour(format(time.hour, true))
    setMinute(format(time.minute))
    setPeriod(period)
  }

  function refresh() {
    const interval = setInterval(setTime, timeout)
    return () => {
      clearInterval(interval)
    }
  }

  function format(number, toShort) {
    if (toShort && number >= 12) {
      number = number - 12
    }
    return (number <= 9 ? "0" : "") + number
  }

  return (
    <span ref={ref}>
      {hour} <span className={styles.colon}>:</span> {minute} {period}
    </span>
  )
}

export default Clock
